import React, { useState } from 'react';
import { Col, Container, Image, Modal, Row } from 'react-bootstrap';
import d1 from '../assets/document1.webp';
import d2 from '../assets/document2.webp';
import d3 from '../assets/document3.webp';
import d4 from '../assets/document4.webp';
import Line from '../line';
import { useTranslation } from '../../i18n/translator';
const Documents = () => {
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | undefined>(
    undefined
  );
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = (img: string) => {
    setSelectedImage(img);
    setShow(true);
  };

  const documents = [
    { src: d1, alt: 'Document 1' },
    { src: d2, alt: 'Document 2' },
    { src: d3, alt: 'Document 3' },
    { src: d4, alt: 'Document 4' },
  ];

  return (
    <Container style={{ position: 'relative' }}>
      <div style={{ position: 'relative', marginBottom: 50 }}>
        <h1 className='animate slide-left'>{t('translate:documents')}</h1>
        <Line width={'60vw'} top={'130%'} left={-150} />
      </div>
      <Row>
        {documents.map((doc, index) => (
          <Col
            key={index}
            xs={6}
            sm={6}
            md={3}
            className='mb-4 animate slide-up'
          >
            <Image
              src={doc.src}
              thumbnail
              width={'100%'}
              height={'100%'}
              onClick={() => handleShow(doc.src)}
              style={{ cursor: 'pointer', borderRadius: '10px' }}
            />
          </Col>
        ))}
      </Row>
      <Line height={'100%'} top={0} left={-20} />
      <Modal show={show} onHide={handleClose} size='lg'>
        <Image src={selectedImage} fluid />
      </Modal>
    </Container>
  );
};

export default Documents;

import React from "react";
import "../../bootstrap/css/bootstrap.min.css";
import ConsultForm from "./Form";
import "./styles.css"; // Підключаємо стилі
import ImageSlider from "../animations/ImageSlider";
import ph1 from "../assets/slideGallery/1.png";
import ph2 from "../assets/slideGallery/2.png";
import ph3 from "../assets/slideGallery/3.png";
import ph4 from "../assets/slideGallery/4.png";
import ph5 from "../assets/slideGallery/5.png";

const ConsultBlock: React.FC = () => {
  return (
    <div className="consult-block-container">
      <ImageSlider images={[ph1, ph2, ph3, ph4, ph5]} />
      <ConsultForm />
    </div>
  );
};

export default ConsultBlock;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard';
import MainScreen from './components/MainScreen';
import TranslationEditor from './components/TextEditorScreen';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<MainScreen />} />
        <Route path='/admin' element={<AdminLogin />} />
        <Route path='/admin/dashboard' element={<AdminDashboard />} />
        <Route path='/admin/textEditor' element={<TranslationEditor />} />
      </Routes>
    </Router>
  );
};

export default App;

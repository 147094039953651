import MOV1 from './mov/1.png';
import MOV2 from './mov/2.png';
import MOV3 from './mov/3.png';
import MS1 from './ms/1.png';
import OSP1 from './osp/1.png';
import OSP2 from './osp/2.png';
import OSP3 from './osp/3.png';
import AVS1 from './avtoservis/1.png';
import AVS2 from './avtoservis/2.png';
import AVS3 from './avtoservis/3.jpg';
import H1 from './harch/1.png';
import H2 from './harch/2.png';
import MVP1 from './mvp/1.png';
import OOP1 from './oop/1.png';
import OOP2 from './oop/2.png';
import OOP3 from './oop/3.png';
import STZ2 from './stz/2.png';
import STZ1 from './stz/1.png';
import STZ3 from './stz/3.png';
import TO1 from './techOrenda/1.jpg';
import TO2 from './techOrenda/2.jpg';
import TR1 from './transport/1.jpg';
import TR2 from './transport/2.jpg';
import TR3 from './transport/3.jpg';
import BP1 from './bp/1.png';
import CZ1 from './cz/1.png';
import CZ2 from './cz/2.png';
import CZ3 from './cz/3.png';
import Convert from './closedMsg.svg';
import X from './X.svg';
import TopBg2 from './topBg2.jpg';
const CZ = [CZ1, CZ2, CZ3];
const BP = [BP1];
const MOV = [MOV1, MOV2, MOV3];
const MS = [MS1];
const OSP = [OSP1, OSP2, OSP3];
const AVS = [AVS1, AVS2, AVS3];
const HAR = [H1, H2];
const MVP = [MVP1];
const OOP = [OOP1, OOP2, OOP3];
const STZ = [STZ1, STZ2, STZ3];
const TO = [TO1, TO2];
const TR = [TR1, TR2, TR3];

export {
  MOV,
  MS,
  OSP,
  AVS,
  HAR,
  MVP,
  Convert,
  OOP,
  STZ,
  TO,
  TR,
  BP,
  CZ,
  TopBg2,
  X,
};

import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css'; // Імпорт стилів для редактора
import ReactQuill from 'react-quill';
import i18next from 'i18next';

const TranslationEditor: React.FC = () => {
  const [language, setLanguage] = useState<'en' | 'ua' | 'pl' | 'ko'>('ua');
  const languages = ['en', 'ua', 'pl', 'ko'];
  const [translations, setTranslations] = useState<Record<string, string>>({});
  const [editorValues, setEditorValues] = useState<Record<string, string>>({});

  useEffect(() => {
    const bundle = i18next.store.data;

    const currentTranslations = bundle.translate?.[language] || {};

    if (
      typeof currentTranslations === 'object' &&
      currentTranslations !== null
    ) {
      setTranslations(currentTranslations);
      setEditorValues(
        Object.keys(currentTranslations).reduce((acc, key) => {
          acc[key] = currentTranslations[key] || '';
          return acc;
        }, {} as Record<string, string>)
      );
    }
  }, [language]);

  const handleEditorChange = (key: string, value: string) => {
    setEditorValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const updateTranslations = async (
    language: 'en' | 'ua' | 'pl' | 'ko',
    editorValues: any
  ) => {
    try {
      const response = await fetch(
        `https://zahid-resource.com/api/translations/${language}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(editorValues),
        }
      );
      if (!response.ok) {
        alert('Помилка при збереженні спробуйте ще');
      }
      alert('Дані успішно збережено');

      return response.text();
    } catch (e) {
      console.error(e);
    }
  };
  const handleSave = () => {
    if (Object.keys(editorValues).length > 0)
      updateTranslations(language, editorValues);
  };

  return (
    <div
      style={{
        padding: 20,
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <h2>Редактор перекладів</h2>

        <select
          value={language}
          onChange={(e) => {
            const lng = e.target.value as 'en' | 'ua' | 'pl' | 'ko';
            setLanguage(lng);
          }}
          className='form-select'
          style={{ width: 100 }}
        >
          {languages.map((lng) => (
            <option key={lng} value={lng}>
              {lng}
            </option>
          ))}
        </select>
        <button
          className='btn btn-primary fixed-bottom'
          style={{
            width: 'fit-content',
            marginLeft: 'auto',
            marginRight: 10,
            marginBottom: 10,
          }}
          onClick={handleSave}
        >
          Зберегти переклади
        </button>
      </div>
      {Object.keys(translations).map((key) => (
        <div
          key={key}
          style={{
            marginBottom: '20px',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
          }}
        >
          <label className='input-group-text' style={{ width: 'fit-content' }}>
            {key}
          </label>
          <ReactQuill
            theme='snow'
            value={editorValues[key]}
            onChange={(value) => handleEditorChange(key, value)}
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline'],
                [{ align: [] }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }],
                ['link', 'image'],
                ['clean'], // Кнопка для очищення форматування
              ],
            }}
            style={{ minHeight: '100px' }} // Висота редактора
          />
        </div>
      ))}

      <button className='btn btn-outline-secondary' onClick={handleSave}>
        Зберегти переклади
      </button>
    </div>
  );
};

export default TranslationEditor;

import React from 'react';
import './Partners.css';
import Green from '../assets/green.webp';
import Prestige from '../assets/prestige.webp';
import Max from '../assets/max.webp';
import Tree from '../assets/Elitfrukt.webp';
import Industrial from '../assets/industrial.webp';
import { Image, Row, Col } from 'react-bootstrap';
import './style.css';
import Line from '../line';
import { useTranslation } from '../../i18n/translator';

const partners = [
  {
    name: 'Industrial Park',
    imgSrc: Industrial,
    alt: 'Industrial Park логістичний партнер',
  },
  {
    name: 'Greenhouse',
    imgSrc: Green,
    alt: 'Greenhouse логістичний партнер',
  },
  {
    name: 'Prestige Express',
    imgSrc: Prestige,
    alt: 'Prestige Express логістичний партнер',
  },
  {
    name: 'Max Logistics',
    imgSrc: Max,
    alt: 'Max Logistics логістичний партнер',
  },
  {
    name: 'Tree Logo',
    imgSrc: Tree,
    alt: 'Tree Logo логістичний партнер',
  },
];

const Partners: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div
      className='text-center my-5'
      style={{ maxWidth: '99.9vw', overflow: 'hidden' }}
    >
      <h1 className='partners-title slide-down animate'>
        {t('translate:our_partners')}
      </h1>

      <Row
        className='align-items-center'
        style={{
          justifyContent: 'center',
          width: '99.9vw',
          rowGap: 10,
        }}
      >
        {partners.map((partner, index) => (
          <Col
            key={index}
            xs={12}
            sm={6}
            md={4}
            lg={2}
            style={{
              height: '5vh',
              maxHeight: 100,
              marginBottom: 25,
              marginTop: 25,
              maxWidth: '49%',
            }}
            className='d-flex justify-content-center align-items-center position-relative'
          >
            {index !== 0 && (
              <Line
                height={50}
                left={0}
                top={0}
                className={
                  index === 3 ? 'md-hide' : index === 4 ? 'sm-hide' : ''
                }
              />
            )}

            <Image
              src={partner.imgSrc}
              alt={partner.alt}
              className='partner-logo img-fluid slide-up animate'
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Partners;
